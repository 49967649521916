
import { defineComponent, reactive, toRefs,nextTick } from "vue";
import useTableHeight from "@/hooks/useTableHeight";
import useFetchCommon from "./useFetchCommon";
import { getRvapiList,getRvBussList } from "@/API/rvapi";
import Pagination from "@/views/Guidang/components/Pagination.vue";
import { RvapirListProps, RvapiParams, TypeLists } from "./types";
import blobDownload from "./blobDownload";
import { message } from 'ant-design-vue';
type Key = string | number;
const columns = [
  {
    title: "Type",
    dataIndex: "business_type",
    key: "type",
    width: 160,
    align: "center",
  },
  {
    title: "File Name",
    dataIndex: "attachment_s3_name",
    key: "fileName",
    width: 260,
    align: "center",
  },
  {
    title: "Operation",
    dataIndex: "",
    key: "Operation",
    width: 160,
    slots: { customRender: "operation" },
    align: "center",
  },
];
export default defineComponent({
  name: "Guidang",
  components: {
    Pagination,
  },
  setup() {
    const { tableHeight } = useTableHeight();
    const typeList = reactive<TypeLists[]>([
     
    ]);
    getRvBussList({}).then(res=>{
      res.forEach(item=>{
        typeList.push({
          value:item,
          label:item
        })
       
      })
      console.log(res)
    })
    const state = reactive<{
      selectedRowKeys: Key[];
      selectedRows: RvapirListProps[];
    }>({
      selectedRowKeys: [], // Check here to configure the default column
      selectedRows: [],
    });
    const onSelectChange = (selectedRowKeys: Key[], selectedRows: []) => {
      console.log("selectedRowKeys changed: ", selectedRowKeys, selectedRows);
      state.selectedRowKeys = selectedRowKeys;
      state.selectedRows = selectedRows;
    };
    const rvapiParams = reactive<RvapiParams>({
      name: "",
      type: "",
    });

    const { data, pagination, refreshFetch } = useFetchCommon<
      RvapirListProps[]
    >({
      params: rvapiParams,
      initData: [],
      fetchApi: getRvapiList,
      isPagination: true,
      isend: true,
    });
    
    const handlerReset = () => {
      Object.assign(rvapiParams, {
        name: "",
        type: "",
      });
      pagination.num = 1;
      refreshFetch();
    };

    const handlerSearch = () => {
      pagination.num = 1;
      refreshFetch();
    };

    //分页事件处理
    const handlerPaginationChange = (page: number): void => {
      pagination.num = page;
      refreshFetch(false);
    };

    const handlerShowSizeChange = (current: number, pageSize: number): void => {
      pagination.size = pageSize;
      Object.assign(pagination, { num: current, size: pageSize });
      refreshFetch(false);
    };
    const downLoadFile = (value: any) => {
      console.log(value);
      blobDownload({
        url: `/rvapi/ar/download`,
        method: "get",
        params: {
          ids: value.id,
        },
      });
    };
    const downAll = () => {
      if(state.selectedRowKeys.length){
         let id: any='';
      state.selectedRows.forEach((item) => {
        id += `${item.id},`;
      });
      id = id.substring(0, id.length - 1);
      console.log(id)
      const ids={
        id
      }
      downLoadFile(ids);
      }else{
         message.error('Please select at least one application.');
      }
     
    };

    return {
      tableHeight,
      columns,
      data,
      pagination,
      ...toRefs(state),
      refreshFetch,
      ...toRefs(rvapiParams),
      onSelectChange,
      handlerReset,
      handlerSearch,
      handlerPaginationChange,
      handlerShowSizeChange,
      downLoadFile,
      typeList,
      downAll,
    };
  },
});
