/*
 * @Author: guowu
 * @Date: 2021-11-29 09:48:20
 * @LastEditTime: 2021-12-10 23:55:09
 */
import { onMounted, reactive, ref } from "vue";

interface Pagination {
    size: number; //每页多少条
    num: number; //当前页数
    total: number; //总条数
}

interface Params {
    [key: string]: any;
}

/**
 * 所有的返回的分页都是这个数据格式
 * @param parameter
 * @param parameter params ajax 查询参数
 * @param parameter initData 初始化数据
 * @param parameter fetchApi ajax请求api
 * @param parameter resTransform response 处理函数
 * @param parameter isPagination 是否带分页查询
 *
 */
function useFetchCommon<T>(parameter: { params?: Params; initData: T; fetchApi: Function; resTransform?: Function; isPagination?: boolean;isend: boolean }) {
    const pagination = reactive<Pagination>({
        size: parameter.params?.sizi ?? 10,
        num: 1,
        total: 0,
    })

    const data = ref<T>(parameter.initData);

    /**
     *
     * @param resetPage 是否重置分页page 1
     */
    const refreshFetch = (resetPage = true): void => {
        let _params: Params = {};
        if (parameter.isPagination) {
            _params  = resetPage ?  Object.assign({}, parameter.params, pagination, { num: 1 }) : Object.assign({}, parameter.params, pagination);
            'total' in _params && delete _params.total;
        } else {
            _params = parameter.params ?? {};
        }
        Object.keys(_params).forEach(item=>{
            if(_params[item]==undefined){
                _params[item]=''
            }
        })
        console.log(_params);
        parameter.fetchApi(_params).then((res: any) => {
            parameter.resTransform && parameter.resTransform(res);
            if ( 'total' in res) {
                Object.assign(pagination, {  total: res.total });
            }
            console.log(pagination,'pagination');
            //处理返回的res.list 和 res.data
            if ('list' in res) {
                data.value = res.list;
            } else if ('data' in res) {
                data.value = res.data;
            } else {
                data.value = res;
            }
            if(parameter.isend){
                (data.value as any).forEach((item: any) => {
                    item['attachment_s3_name']=decodeURIComponent(item['attachment_s3_name']) 
                });
            }
           
        })
    }

    onMounted(refreshFetch);

    return {
        pagination,
        data,
        refreshFetch
    };
}

export default useFetchCommon;
