/*
 * @Author: guowu
 * @Date: 2021-12-08 16:53:05
 * @LastEditTime: 2021-12-15 11:40:35
 */
import { RvapirListProps,RvapiParams } from "@/views/Guidang/GuiDangIndex/types";
import {  fetchGet  } from './index'
export const getRvapiList = (params: RvapiParams): Promise<RvapirListProps[]> => {
    return fetchGet('/rvapi/ar/list', { params });
}
export const getRvBussList = (params: {}): Promise<string[]> => {
    return fetchGet('/rvapi/ar/buss', { params });
}

